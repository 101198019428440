export default function () {
  return {
    search: "",
    category_type: null,
    category: null,
    license: null,
    min_price: null,
    max_price: null,
    inventory_grouping: {
      available: false,
      value: null,
    },
    listingAction: null,
    entryPoint: null,
    ordersPerPage: "10",
  };
}
